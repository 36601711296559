import React from 'react'
import { coachingCardsData } from '../../data/coachingCardsData'
import Fade from 'react-reveal'

const CoachingCards = () => {
    return (
        <div className="bg-bgGray py-4" >
            <div className="max-w-screen-xl sm:w-4/5 md:w-full m-auto">
                <p className="text-lightBlue2 sm:text-xl font-bold mx-2">Develop your skills, learn latest techniques and achieve your career goals from anywhere in the world.</p>
                <div className=" md:mx-14 md:flex">
                    {coachingCardsData.map((data,i)=> {
                        return (
                            <Fade left key={i} >
                                <div className="border shadow-md rounded-md m-4 bg-white">
                                    <img className="m-auto mt-4 mb-2" src={data.icon} style={{width:'56px', height:'56px'}}/>
                                    <p className=" text-mainColor text-xl font-bold py-2 rounded-t-md text-center">{data.title}</p>
                                    {data.description}
                                </div>
                            </Fade>
                        )})}
                </div>
            </div>
        </div>
    )
}
export default CoachingCards