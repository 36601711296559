import React from "react"
import { Link } from "gatsby"
import Zoom from "react-reveal/Zoom"
const Courses = () => {
  return (
    <div>
      <div className="text-xl md:text-2xl lg:text-3xl text-white bg-mainColor p-1 mb-4 text-center">
        Coaching Catalogue
      </div>
      <div className="sm:text-xl text-lg text-mainColor my-4  text-center max-w-screen-xl w-4/5 md:w-full m-auto">
        Enjoy a large variety of coaching and mentoring type of sessions with
        professionals in the field. You are the creator of your own career & we
        will guide you through the journey.{" "}
      </div>
      <div>
        <div className="my-4 max-w-screen-xl w-4/5 md:w-full m-auto">
          <p className="text-lightBlue2 font-bold text-left mx-4 sm:text-xl">
            How our collaboration begins (approx. 30 min sessions one on one):
          </p>
          <div className="grid md:grid-cols-4 md:mx-4">
            <Zoom>
              <div>
                <div className=" shadow-lg m-2  rounded-md border-2 ">
                  <div className="flex border-b items-center justify-evenly">
                    <span className="text-mainColor text-xl font-bold items-center">
                      Introductory call *
                    </span>
                    <span className="rounded-full p-3 bg-lightBlue2 text-white">
                      $50
                    </span>
                  </div>
                  <p className=" text-mainColor p-4">
                    {" "}
                    Short chat on who we are, who is the client, what are the
                    clients goals and how we can work together
                  </p>
                  <div className="flex justify-center">
                    <button className="bg-mainColor px-4 py-2 text-white rounded-md mb-2">
                      <span
                        className="cursor-pointer text-white hover:text-lightBlue2 rounded-md no-underline p-2"
                        onClick={() => HelpWidget("open")}
                      >
                        Ask for Details
                      </span>
                    </button>
                  </div>
                </div>
                <div className="flex items-end mt-4">
                  <p>
                    *We will not charge for this call if we can’t help you
                    achieve your goals
                  </p>
                </div>
              </div>
            </Zoom>
          </div>
        </div>
        <div className="py-4 max-w-screen-xl w-4/5 md:w-full m-auto">
          <p className="text-lightBlue2 font-bold text-left mx-4 sm:text-xl">
            On what topics we can work together (approx. 60 min sessions one on
            one):
          </p>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 md:mx-4">
            <Zoom>
              <div className=" shadow-lg m-2  rounded-md border-2 ">
                <div className="flex border-b items-center justify-evenly">
                  <span className="text-mainColor text-xl font-bold">
                    Career progression
                  </span>
                  <span className="rounded-full p-3 bg-lightBlue2 text-white">
                    $100
                  </span>
                </div>
                <p className=" text-mainColor p-4">
                  includes CV review, goals and career path coaching and
                  mentoring customized advice with regards to how you can grow
                  in the cybersecurity field.
                </p>
                <div className="flex justify-center">
                  <button className="bg-mainColor px-4 py-2 text-white rounded-md mb-2">
                    <span
                      className="cursor-pointer text-white hover:text-lightBlue2 rounded-md no-underline p-2"
                      onClick={() => HelpWidget("open")}
                    >
                      Ask for Details
                    </span>
                  </button>
                </div>
              </div>
              <div className=" shadow-lg m-2  rounded-md border-2 ">
                <div className="flex border-b items-center justify-evenly">
                  <span className="text-mainColor text-xl font-bold">
                    Knowledge road map
                  </span>
                  <span className="rounded-full p-3 bg-lightBlue2 text-white">
                    $100
                  </span>
                </div>
                <p className=" text-mainColor p-4">
                  the mentor will provide an overview of worthwhile theoretical
                  and practical content based on your profile and goals and work
                  with you to create a study plan.
                </p>
                <div className="flex justify-center">
                  <button className="bg-mainColor px-4 py-2 text-white rounded-md mb-2">
                    <span
                      className="cursor-pointer text-white hover:text-lightBlue2 rounded-md no-underline p-2"
                      onClick={() => HelpWidget("open")}
                    >
                      Ask for Details
                    </span>
                  </button>
                </div>
              </div>
              <div className=" shadow-lg m-2  rounded-md border-2">
                <div className="flex border-b items-center justify-evenly">
                  <span className="text-mainColor text-xl font-bold">
                    Project & Task Q/A
                  </span>
                  <span className="rounded-full p-3 bg-lightBlue2 text-white">
                    $150
                  </span>
                </div>
                <p className=" text-mainColor p-4">
                  your coach will go over your work, give you specialized inputs
                  to make your work better, act as challenger and discuss
                  available solutions.
                </p>
                <div className="flex justify-center">
                  <button className="bg-mainColor px-4 py-2 text-white rounded-md mb-2">
                    <span
                      className="cursor-pointer text-white hover:text-lightBlue2 rounded-md no-underline p-2"
                      onClick={() => HelpWidget("open")}
                    >
                      Ask for Details
                    </span>
                  </button>
                </div>
              </div>
              <div className=" shadow-lg m-2  rounded-md border-2 ">
                <div className="flex border-b items-center justify-evenly">
                  <span className="text-mainColor text-xl font-bold">
                    {" "}
                    Expert Consultation
                  </span>
                  <span className="rounded-full p-3 bg-lightBlue2 text-white">
                    $150
                  </span>
                </div>
                <p className=" text-mainColor p-4">
                  {" "}
                  discuss any cybersecurity questions / problems (ie deciding on
                  a strategy for your business, launching a cybersecurity
                  software product)
                </p>
                <div className="flex justify-center">
                  <button className="bg-mainColor px-4 py-2 text-white rounded-md mb-2">
                    <span
                      className="cursor-pointer text-white hover:text-lightBlue2 rounded-md no-underline p-2"
                      onClick={() => HelpWidget("open")}
                    >
                      Ask for Details
                    </span>
                  </button>
                </div>
              </div>
            </Zoom>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  )
}
export default Courses
