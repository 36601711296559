import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TrainingHeader from "../components/trainingsComponents/trainingHeader"
import CoachingCards from "../components/coachingComponents/coachingCards"
import Courses from "../components/coachingComponents/courses"
const Coaching = () => {
  return (
    <Layout>
      <Seo
        title="Coaching Center | Ricta Technologies"
        description="List of coaching/consultancy courses for Ricta Technologies"
      />
      <div className="">
        <TrainingHeader
          mainTitle={"Coaching/Consultancy Office"}
          text={
            "We believe that by giving high quality and customized advice, our clients will achieve faster and better personal and professional results. Grow your knowledge and your opportunities with thought leadership, training and tools. All sessions are held by professional experts with 15+ years of field experience."
          }
          color={"blue"}
        />
        <div className="">
          <CoachingCards />
          <Courses />
        </div>
      </div>
    </Layout>
  )
}
export default Coaching
