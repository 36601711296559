import React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import icon1 from '../images/streamline-icon-settings-human@48x48.svg'
import icon3 from '../images/streamline-icon-ranking-star-top@48x48.svg'
import icon2 from '../images/streamline-icon-stairs-person-descend@48x48.svg'
export const coachingCardsData = [
    {
        title: 'Build lateral knowledge',
        description: (
            <div className="text-left w-5/6 m-auto">
                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/>Being able to approach problems from different angles is an essential skill.</p>
                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/>We can help you map your experience and review the option available for your customized knowledge growth plan. All one needs to succeed is the right guidance & tools and we can help with both.</p>
            </div>
        ),
        icon: icon1
    },
    {
        title:'Own your career progression',
        description:(
            <div className="text-left w-5/6 m-auto">
                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/>Utilize coaching to get a competitive advantage to reach the next level in your career.  </p>
                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/> Step up your career game plan, prepare for interviews, job search & promotion. Our sessions are a crash course on being an A player at work and achieve exponential growth.
                </p>
            </div>),
        icon: icon2
    },
    {
        title:(<>Get solutions to real life situations</>),
        description: (
            <div className="text-left w-5/6 m-auto">
                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/>
                Feeling anxious and tired of figuring out on your own specific digital forensics, OPSEC, OSINT topic related questions?
                </p>
                <p className="font-semibold text-mainColor flex"><CheckIcon className="text-yellowColor mr-1"/>
                Work together with our coaches to address real life situations and find out best options on how to solve problems / tasks and other questions you might have.
                </p>
            </div>
        ),
        icon: icon3
    }
]